import { Route, Switch, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { SyncLoader } from "react-spinners";

import SplitScreen from "./components/layout/SplitScreen";
import TeamQueryView from "./components/team/TeamQueryView";
import TeamResultsView from "./components/team/TeamResultsView";
import TitlePage from "./components/TitlePage";

import "./App.css";

function App() {
  // State hooks
  const [queryData, setQueryData] = useState({});
  const [queryContent, setQueryContent] = useState({});
  const [scorecardData, setScorecardData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [result, setResult] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Function to make a POST request to the authentication endpoint
    const requestToken = async () => {
      try {
        const BACKEND_URL = process.env.REACT_APP_API_URL;
        const response = await fetch(`${BACKEND_URL}/authenticate`, {
          method: "POST",
        });

        if (response.ok) {
          const data = await response.json();
          const obtainedToken = data.token;

          // Update the state to reflect the token
          setToken(obtainedToken);
        } else {
          console.error("Authentication failed");
        }
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    };

    // Trigger the token request when the component mounts
    requestToken();
  }, []); // Empty dependency array ensures the effect runs only once on mount

  // Use location hook to access URL parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const league = queryParams.get("league");

  if (process.env.NODE_ENV !== "production") {
    console.log("League param value in URL:", league);
  }

  const handleQuerySubmit = (
    queryData,
    queryContent,
    scorecardData,
    result = true
  ) => {
    setQueryData(queryData);
    setQueryContent(queryContent);
    setScorecardData(scorecardData);
    setResult(result);
  };

  const handleQueryReset = () => {
    setResult(false);
  };

  const updateFetching = (fetch) => {
    setFetching(fetch);
  };

  return (
    <Switch>
      <Route path="/" exact>
        {league ? (
          <SplitScreen leftWeight={1} rightWeight={3}>
            {/* Left */}
            <div
              style={{
                userSelect: "none", //Prevent text from being selected
              }}
            >
              <div
                style={{
                  width: "95%",
                  color: "#000",
                  padding: "5px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  boxSizing: "border-box", // Ensure padding is included in the total width
                  margin: "5px", // Add some space between the banner and TeamQueryView
                  display: "block",
                  textAlign: "center", // Center the content
                }}
              >
                <div style={{ padding: "5px" }}>
                  <b style={{ fontSize: "1.8rem", textTransform: "uppercase" }}>
                    Stats Genie
                  </b>
                  <br />
                </div>

                <div>
                  <a href="http://crikit.io">
                    <img
                      src="https://s3.amazonaws.com/crikit/PoweredByCrikIT.png"
                      height="18"
                      alt="CrikIT"
                    />
                  </a>
                </div>
              </div>
              {token ? (
                <TeamQueryView
                  token={token}
                  league={league}
                  onQuerySubmit={handleQuerySubmit}
                  onQueryReset={handleQueryReset}
                  updateFetching={updateFetching}
                />
              ) : (
                <p>Loading...</p>
              )}
            </div>

            {/* Right */}
            <div>
              {fetching && (
                <div style={{ textAlign: "center", marginTop: "10px" }}>
                  <SyncLoader color="#3498db" size={10} margin={2} />
                  <p style={{ marginTop: "10px" }}>Fetching results...</p>
                </div>
              )}
              {result && (
                <div
                  style={{
                    overflow: "auto",
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                    padding: "10px",
                  }}
                >
                  <TeamResultsView
                    scorecardData={scorecardData}
                    queryData={queryData}
                    queryContent={queryContent}
                  />
                </div>
              )}
            </div>
          </SplitScreen>
        ) : (
          <TitlePage />
        )}
      </Route>
    </Switch>
  );
}

export default App;
