import SortingTable from "../SortingTable";
import { getColumns, getData, getInitialSortState } from "./TableBuilder";

// For debugging table
// import MOCK_DATA from "../../mockdata/Teams_OverallFigures.json";
// import { COLUMNS } from "../../mockdata/columns";

function TeamResultsView(props) {
  const tableData = getData(
    props.queryData.ViewFormat,
    props.queryData.TeamTotals,
    props.scorecardData,
    props.queryData,
    props.queryContent
  );

  return (
    <section>
      {tableData.length === 0 ? (
        <p>No records available to match this query</p>
      ) : (
        <SortingTable
          columns={getColumns(props.queryData.ViewFormat)}
          data={tableData}
          initialState={getInitialSortState(props.queryData.ViewFormat)}

          // columns={COLUMNS}
          // data={MOCK_DATA}
          // initialState={{ sortBy: [{ id: "matches", desc: true }] }}
        />
      )}
    </section>
  );
}

export default TeamResultsView;
