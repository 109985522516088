import React from "react";
import "./TitlePage.css";

const TitlePage = () => {
  return (
    <div className="container">
      <header className="header">
        <h1 className="title">Stats Genie</h1>
        <p className="subtitle">"Your query is my command!"</p>
      </header>

      <div className="body">
        <p>
          Explore team stats effortlessly! Select teams, venues, and tournaments
          to uncover in-depth insights into cricket performance.
        </p>

        <p>
          Refine searches by outcomes—winning, losing, tied—offering a nuanced
          view of team dynamics.
        </p>

        <p>
          Get a quick overview with Summarized Figures or dive deep with
          detailed Innings Lists, featuring key metrics and match instances.
        </p>

        <p>
          An intuitive interface and rich stats make Stats Genie a must-have
          tool for cricket enthusiasts.
        </p>
        <p>
          Created by Anoop Joy
        </p>
      </div>

      <footer className="footer">
        <div className="row footerRow text-center">
          <div className="col-md-12 text-center hidden-sm hidden-xs">
            <a href="http://crikit.io"><img src="https://s3.amazonaws.com/crikit/PoweredByCrikIT.png" height="22" alt="CrikIT"/></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TitlePage;
