import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import styled from "styled-components";

const BACKEND_URL = process.env.REACT_APP_API_URL;
// Style code
const Form = styled.form`
  /* Add some padding to the left and right of the form */
  padding-left: 20px;
  padding-right: 20px;

  label {
    font-size: 1rem;
  }

  button {
    width: 100%; /* Make buttons take full width on all screens */
    margin-top: 10px; /* Add some space between the buttons */
    font-size: 1.2rem;
  }

  /* Additional styles for specific buttons */
  button[type="submit"] {
    background-color: #4caf50; /* Green color for submit button */
    color: white; /* Text color for submit button */
  }

  button[type="reset"] {
    background-color: #f44336; /* Red color for reset button */
    color: white; /* Text color for reset button */
  }
`;

const GroupedLabels = styled.div`
  div {
    margin-bottom: 10px;
  }
`;

// Custom styles for Select components
const selectStyles = {
  control: (base) => ({
    ...base,
    width: "100%", // Adjust width for smaller screens
    marginBottom: 10, // Add margin between Select components
  }),
};
//--------------------------------------------------------------------------
function TeamQueryView(props) {
  // Declare state variables
  const [isLoading, setIsLoading] = useState(true);
  const [loadedQueryContent, setLoadedQueryContent] = useState({});

  const resetQueryState = {
    Teams: [],
    OppositionTeams: [],
    Tournaments: [],
    Venues: [],
    Seasons: [],
    MatchResult: new Set(),
    BattingOrder: "either",
    ViewFormat: "overall",
    TeamTotals: "batting",
  };
  const [queryData, setQueryData] = useState({ ...resetQueryState });
  const teamSelectRef = useRef();
  const oppTeamSelectRef = useRef();
  const tournamentSelectRef = useRef();
  const venueSelectRef = useRef();
  const seasonSelectRef = useRef();

  const formattedQueryData = {};

  // TODO: Consolidate these update methods.
  const updateTeams = (value) => {
    setQueryData({ ...queryData, Teams: value });
  };

  const updateOppositionTeams = (value) => {
    setQueryData({ ...queryData, OppositionTeams: value });
  };

  const updateTournaments = (value) => {
    setQueryData({ ...queryData, Tournaments: value });
  };

  const updateVenues = (value) => {
    setQueryData({ ...queryData, Venues: value });
  };

  const updateSeasons = (value) => {
    setQueryData({ ...queryData, Seasons: value });
  };

  const updateMatchResult = (value) => {
    // Updating the state in an immutable way by creating
    // a new Set based on the previous state.
    setQueryData((prevData) => {
      const newMatchResult = new Set(prevData.MatchResult);
      // If value is in the set, remove it else add it.
      if (newMatchResult.has(value)) {
        newMatchResult.delete(value);
      } else {
        newMatchResult.add(value);
      }

      return {
        ...prevData,
        MatchResult: newMatchResult,
      };
    });
  };

  const updateBattingOrder = (value) => {
    setQueryData({ ...queryData, BattingOrder: value });
  };

  const updateViewFormat = (value) => {
    setQueryData({ ...queryData, ViewFormat: value });
  };

  const updateTeamTotals = (value) => {
    setQueryData({ ...queryData, TeamTotals: value });
  };
  //---------------------------------------------------------------
  // Handle form submission
  const handleSubmit = async (event) => {
    // Submit form data to server
    event.preventDefault();

    props.updateFetching(true);

    // For debugging
    if (process.env.NODE_ENV !== "production") {
      console.log("User query info:", queryData);
    }

    // Convert the queryData to the format that the server expects
    formattedQueryData["League"] = props.league;
    for (const key in queryData) {
      if (Array.isArray(queryData[key])) {
        formattedQueryData[key] = queryData[key].map((el) =>
          loadedQueryContent[key].indexOf(el)
        );
      } else {
        formattedQueryData[key] = queryData[key];
      }
    }

    // For debugging
    if (process.env.NODE_ENV !== "production") {
      console.log("Formatted user query info:", formattedQueryData);
    }

    // Fetch the data
    try {
      let params = "?";

      for (const key in formattedQueryData) {
        if (key === "League") {
          params += `league=${formattedQueryData[key]}&`;
        } else if (key === "Teams") {
          params += `team=${formattedQueryData[key]}&`;
        } else if (key === "OppositionTeams") {
          params += `opposition=${formattedQueryData[key]}&`;
        } else if (key === "Tournaments") {
          params += `tournament=${formattedQueryData[key]}&`;
        } else if (key === "Venues") {
          params += `ground=${formattedQueryData[key]}&`;
        } else if (key === "Seasons") {
          params += `season=${formattedQueryData[key]}&`;
        }
      }
      // For debugging
      if (process.env.NODE_ENV !== "production") {
        console.log("Request Params:", params);
      }

      const response = await fetch(`${BACKEND_URL}/team/batting` + params, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const scData = await response.json();

      // For debugging
      if (process.env.NODE_ENV !== "production") {
        console.log("Scorecards fetched:", scData);
      }

      // Set state values for "Query Result View"
      props.onQuerySubmit(queryData, loadedQueryContent, scData);

      props.updateFetching(false);
    } catch (e) {
      console.error(e);
      props.updateFetching(false);
    }
  };

  const handleReset = () => {
    // Clear the Select components first
    teamSelectRef.current.clearValue();
    oppTeamSelectRef.current.clearValue();
    tournamentSelectRef.current.clearValue();
    venueSelectRef.current.clearValue();
    seasonSelectRef.current.clearValue();

    setQueryData({ ...resetQueryState });

    // Clear the "Query Result View"
    props.onQueryReset();
  };
  //---------------------------------------------------------------
  const league = props.league;
  const token = props.token;
  useEffect(() => {
    setIsLoading(true);

    const fetchContent = {};
    // TODO: Should these multiple fetch commands be unified into a single fetch command?

    const fetchTeams = async () => {
      try {
        const params = `?league=${league}`;
        const response = await fetch(
          `${BACKEND_URL}/team/internal/getteams` + params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        fetchContent.Teams = await response.json();
      } catch (error) {
        console.error(error);
      }

      // // mockdata
      // fetchContent.Teams = [
      //   { _id: "8c322275a5d29c2ca3e26080", name: "Avengers" },
      //   { _id: "097c49ef69dc173a3a69d5a7", name: "Incredibles" },
      //   { _id: "6c2d11bd98aa7a6b5a5df5ec", name: "Justice League" },
      // ];

      fetchContent.Teams = fetchContent.Teams.map((team) => {
        return { value: team._id, label: team.name };
      });
    };

    const fetchOppositionTeams = async () => {
      try {
        const params = `?league=${league}`;
        const response = await fetch(
          `${BACKEND_URL}/team/internal/getteams` + params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        fetchContent.OppositionTeams = await response.json();
      } catch (error) {
        console.error(error);
      }

      //   // mockdata
      //   fetchContent.OppositionTeams = [
      //   { _id: "8c322275a5d29c2ca3e26080", name: "Avengers" },
      //   { _id: "097c49ef69dc173a3a69d5a7", name: "Incredibles" },
      //   { _id: "6c2d11bd98aa7a6b5a5df5ec", name: "Justice League" },
      // ];

      fetchContent.OppositionTeams = fetchContent.OppositionTeams.map(
        (team) => {
          return { value: team._id, label: team.name };
        }
      );
    };

    const fetchVenues = async () => {
      try {
        const params = `?league=${league}`;
        const response = await fetch(
          `${BACKEND_URL}/team/internal/getgrounds` + params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchContent.Venues = await response.json();
      } catch (error) {
        console.error(error);
      }

      // // mockdata
      // fetchContent.Venues = [
      //   { _id: "4a90ebdbe91f74ad3b7d8f41", name: "Ashland" },
      //   { _id: "e3b4123cef68301fa12bf5d0", name: "Sargent Field" },
      // ];

      fetchContent.Venues = fetchContent.Venues.map((venue) => {
        return { value: venue._id, label: venue.name };
      });
    };

    const fetchSeasons = async () => {
      try {
        const params = `?league=${league}`;
        const response = await fetch(
          `${BACKEND_URL}/team/internal/getseasons` + params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchContent.Seasons = await response.json();
      } catch (error) {
        console.error(error);
      }

      //   // mockdata
      // fetchContent.Seasons = [
      //   { _id: "2021" },
      //   { _id: "2020" },
      //   { _id: "2018" },
      // ];

      fetchContent.Seasons = fetchContent.Seasons.map((season) => {
        return { value: season._id, label: season._id };
      });
    };

    const fetchTournaments = async () => {
      try {
        const params = `?league=${league}`;
        const response = await fetch(
          `${BACKEND_URL}/team/internal/gettournaments` + params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchContent.Tournaments = await response.json();
      } catch (error) {
        console.error(error);
      }

      // // mockdata
      // fetchContent.Tournaments = [
      //   { _id: "8c322275a5d29c2ca3e26080", name: "Avengers" },
      //   { _id: "097c49ef69dc173a3a69d5a7", name: "Incredibles" },
      //   { _id: "6c2d11bd98aa7a6b5a5df5ec", name: "Justice League" },
      // ];

      fetchContent.Tournaments = fetchContent.Tournaments.map((tournament) => {
        return { value: tournament._id, label: tournament.name };
      });
    };

    const fetchSite = async () => {
      try {
        const params = `?league=${league}`;
        const response = await fetch(
          `${BACKEND_URL}/team/internal/getsite` + params,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchContent.Site = await response.text();
      } catch (error) {
        console.error(error);
      }
    };

    fetchTeams()
      .then(fetchOppositionTeams)
      .then(fetchVenues)
      .then(fetchSeasons)
      .then(fetchTournaments)
      .then(fetchSite)
      .then(() => {
        setIsLoading(false);
        setLoadedQueryContent(fetchContent);
      });

    // For debugging
    if (process.env.NODE_ENV !== "production") {
      console.log("Fetched content for dropdown options:", fetchContent);
    }
  }, [league, token]);
  //---------------------------------------------------------------
  if (isLoading) {
    return (
      <section>
        <p>Loading...</p>
      </section>
    );
  }

  return (
    <div>
      <section>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <label>
            <b>Team:</b>
            <Select
              isSearchable
              isMulti
              styles={selectStyles}
              placeholder="All Teams"
              options={loadedQueryContent.Teams}
              ref={teamSelectRef}
              onChange={(value) => {
                updateTeams(value);
              }}
            />
          </label>

          <label>
            <b>Opposition:</b>
            <Select
              isSearchable
              isMulti
              styles={selectStyles}
              placeholder="All Opposition Teams"
              options={loadedQueryContent.OppositionTeams}
              ref={oppTeamSelectRef}
              onChange={(value) => {
                updateOppositionTeams(value);
              }}
            />
          </label>

          <label>
            <b>Tournament:</b>
            <Select
              isSearchable
              isMulti
              styles={selectStyles}
              placeholder="All Tournaments"
              options={loadedQueryContent.Tournaments}
              ref={tournamentSelectRef}
              onChange={(value) => {
                updateTournaments(value);
              }}
            />
          </label>

          <label>
            <b>Venue/Ground:</b>
            <Select
              isSearchable
              isMulti
              styles={selectStyles}
              placeholder="All Grounds"
              options={loadedQueryContent.Venues}
              ref={venueSelectRef}
              onChange={(value) => {
                updateVenues(value);
              }}
            />
          </label>

          <label>
            <b>Season:</b>
            <Select
              isSearchable
              isMulti
              styles={selectStyles}
              placeholder="All Seasons"
              options={loadedQueryContent.Seasons}
              ref={seasonSelectRef}
              onChange={(value) => {
                updateSeasons(value);
              }}
            />
          </label>

          <GroupedLabels>
            <label>
              <b>Match Result:</b>
              <div>
                <label htmlFor="cb_matchwon">
                  <input
                    type="checkbox"
                    id="cb_matchwon"
                    name="matchresult_options"
                    value="W"
                    checked={queryData.MatchResult.has("W")}
                    onChange={(e) => updateMatchResult(e.target.value)}
                  />
                  Won
                </label>

                <label htmlFor="cb_matchlost">
                  <input
                    type="checkbox"
                    id="cb_matchlost"
                    name="matchresult_options"
                    value="L"
                    checked={queryData.MatchResult.has("L")}
                    onChange={(e) => updateMatchResult(e.target.value)}
                  />
                  Lost
                </label>

                <label htmlFor="cb_matchtied">
                  <input
                    type="checkbox"
                    id="cb_matchtied"
                    name="matchresult_options"
                    value="T"
                    checked={queryData.MatchResult.has("T")}
                    onChange={(e) => updateMatchResult(e.target.value)}
                  />
                  Tied
                </label>

                <label htmlFor="cb_matchnoresult">
                  <input
                    type="checkbox"
                    id="cb_matchnoresult"
                    name="matchresult_options"
                    value="NR"
                    checked={queryData.MatchResult.has("NR")}
                    onChange={(e) => updateMatchResult(e.target.value)}
                  />
                  No result
                </label>
              </div>
            </label>

            <label>
              <b>Batting Order:</b>
              <div>
                <label htmlFor="rb_batfirst">
                  <input
                    type="radio"
                    id="rb_batfirst"
                    name="batorder_options"
                    value="first"
                    checked={queryData.BattingOrder === "first"}
                    onChange={() => updateBattingOrder("first")}
                  />
                  Batting first
                </label>
                <label htmlFor="rb_batsecond">
                  <input
                    type="radio"
                    id="rb_batsecond"
                    name="batorder_options"
                    value="second"
                    checked={queryData.BattingOrder === "second"}
                    onChange={() => updateBattingOrder("second")}
                  />
                  Batting second
                </label>
                <label htmlFor="rb_bateither">
                  <input
                    type="radio"
                    id="rb_bateither"
                    name="batorder_options"
                    value="either"
                    checked={queryData.BattingOrder === "either"}
                    onChange={() => updateBattingOrder("either")}
                  />
                  Either
                </label>
              </div>
            </label>

            <label>
              <b>View Format:</b>
              <div>
                <label htmlFor="rb_overall">
                  <input
                    type="radio"
                    id="rb_overall"
                    name="viewformat_options"
                    value="overall"
                    checked={queryData.ViewFormat === "overall"}
                    onChange={() => updateViewFormat("overall")}
                  />
                  Overall Figures
                </label>

                <label htmlFor="rb_innings">
                  <input
                    type="radio"
                    id="rb_innings"
                    name="viewformat_options"
                    value="innings"
                    checked={queryData.ViewFormat === "innings"}
                    onChange={() => updateViewFormat("innings")}
                  />
                  Innings by innings list
                </label>
              </div>
            </label>

            <label>
              <b>Team totals of:</b>
              <div>
                <label htmlFor="rb_battingteam">
                  <input
                    type="radio"
                    id="rb_battingteam"
                    name="teamtotals_options"
                    value="batting"
                    checked={queryData.TeamTotals === "batting"}
                    onChange={() => updateTeamTotals("batting")}
                  />
                  Selected Team
                </label>
                <label htmlFor="rb_bowlingteam">
                  <input
                    type="radio"
                    id="rb_bowlingteam"
                    name="teamtotals_options"
                    value="bowling"
                    checked={queryData.TeamTotals === "bowling"}
                    onChange={() => updateTeamTotals("bowling")}
                  />
                  Opposition Team
                </label>
              </div>
            </label>
          </GroupedLabels>

          <button type="submit">Submit Query</button>
          <button type="reset">Reset Query</button>
        </Form>
      </section>
    </div>
  );
}

export default TeamQueryView;
