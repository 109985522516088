import { Link } from "react-router-dom";

export const getColumns = (viewFormat) => {
  // return columns based on viewformat requested

  if (viewFormat === "overall") {
    return overallFiguresColumns;
  } else if (viewFormat === "innings") {
    return innByInnColumns;
  }
};

export const getData = (
  viewFormat,
  teamTotalsFor,
  scorecardData,
  queryData,
  queryContent
) => {
  if (viewFormat === "overall") {
    return getOverallFiguresData(
      scorecardData,
      queryData,
      queryContent,
      teamTotalsFor
    );
  } else if (viewFormat === "innings") {
    return getInnByInnData(
      scorecardData,
      queryData,
      queryContent,
      teamTotalsFor
    );
  }
};

export const getInitialSortState = (viewFormat) => {
  if (viewFormat === "overall") {
    return { sortBy: [{ id: "won", desc: true }] };
  } else if (viewFormat === "innings") {
    return { sortBy: [{ id: "score", desc: true }] };
  }
};

//---------------------------------------------------
// VIEW FORMAT = OVERALL

const overallFiguresColumns = [
  {
    Header: "Team",
    accessor: "team",
    tooltip: "Sort by team name",
  },
  {
    Header: "Span",
    accessor: "span",
    sortDescFirst: true,
    // TODO: Confirm for folks if sorting by start date is valuable or span duration.

    // Sort by Span duration
    sortType: (a, b) => {
      // Custom sorting logic goes here
      // Return a value indicating how the rows should be sorted
      const spanDurationA = getSpanDuration(a.original.span);
      const spanDurationB = getSpanDuration(b.original.span);

      if (spanDurationA < spanDurationB) return -1;
      if (spanDurationA > spanDurationB) return 1;
      return 0;

      function getSpanDuration(spanStr) {
        const span = spanStr.split("-");
        return +span[1] - +span[0];
      }
    },
    tooltip: "Sort by span duration",
  },
  {
    Header: "Mat",
    accessor: "matches",
    sortDescFirst: true,
    tooltip: "Sort by matches played",
  },
  {
    Header: "Won",
    accessor: "won",
    sortDescFirst: true,
    tooltip: "Sort by matches won",
  },
  {
    Header: "Lost",
    accessor: "lost",
    sortDescFirst: true,
    tooltip: "Sort by matches lost",
  },
  {
    Header: "Tied",
    accessor: "tied",
    sortDescFirst: true,
    tooltip: "Sort by matches tied",
  },
  {
    Header: "NR",
    accessor: "noresult",
    sortDescFirst: true,
    tooltip: "Sort by matches with no result",
  },
  {
    Header: "W/L",
    accessor: "win_loss",
    sortDescFirst: true,
    sortType: (a, b) => {
      let win_loss_A = a.original.win_loss;
      let win_loss_B = b.original.win_loss;

      if (win_loss_A === "-") return 1;
      if (win_loss_B === "-") return -1;

      win_loss_A = +win_loss_A;
      win_loss_B = +win_loss_B;

      if (win_loss_A < win_loss_B) return -1;
      if (win_loss_A > win_loss_B) return 1;
      return 0;
    },
    tooltip: "Sort by win/loss ratio",
  },
  {
    Header: "Ave",
    accessor: "averageRunsPerWicket",
    sortDescFirst: true,
    sortType: (a, b) => {
      let ave_A = a.original.averageRunsPerWicket;
      let ave_B = b.original.averageRunsPerWicket;

      if (ave_A === "-") return 1;
      if (ave_B === "-") return -1;

      ave_A = +ave_A;
      ave_B = +ave_B;

      if (ave_A < ave_B) return -1;
      if (ave_A > ave_B) return 1;
      return 0;
    },
    tooltip: "Sort by average runs per wicket",
  },
  {
    Header: "RPO",
    accessor: "runsPerOver",
    sortDescFirst: true,
    tooltip: "Sort by average runs per over",
  },
  {
    Header: "Inns",
    accessor: "numInnings",
    sortDescFirst: true,
    tooltip: "Sort by number of team innings",
  },
  {
    Header: "HS",
    accessor: "high_score",
    sortDescFirst: true,
    tooltip: "Sort by highest team score",
  },
  {
    Header: "LS",
    accessor: "low_score",
    sortDescFirst: true,
    tooltip: "Sort by lowest allout score",
  },
  {
    Header: "AS",
    accessor: "avg_score",
    sortDescFirst: true,
    tooltip: "Sort by average team Score",
  },
];

const getOverallFiguresData = (
  scorecardData,
  queryData,
  queryContent,
  teamTotalsFor
) => {
  const data = [];

  const teams = getPrimaryTeams(queryData.Teams, queryContent.Teams);

  const oppTeams = getOppositionTeams(
    queryData.OppositionTeams,
    queryContent.Teams
  );

  teams.forEach(({ value: teamId, label: teamName }) => {
    const rowData = {};
    rowData.team = teamName;

    // Filter scorecards by opposition teams
    // -- filter for scorecards where this team (teamId) is playing one of the opponent teams
    let filteredScorecardData = filterScorecardsByOpposition(
      scorecardData,
      oppTeams,
      teamId
    );

    // Filter scorecards based on MatchResult query
    filteredScorecardData = filterScorecardsByMatchResults(
      filteredScorecardData,
      queryData.MatchResult,
      teamId
    );

    // Filter scorecards based on Batting order (Batting first or second) query
    filteredScorecardData = filterScorecardsByBattingOrder(
      filteredScorecardData,
      queryData.BattingOrder,
      teamId
    );

    const matchStats = getMatchStats(teamId, filteredScorecardData);
    rowData.span = matchStats.span;
    rowData.matches = matchStats.numMatch;
    rowData.won = matchStats.numWin;
    rowData.lost = matchStats.numLoss;
    rowData.tied = matchStats.numTies;
    rowData.noresult = matchStats.numNoResult;
    rowData.win_loss = matchStats.winLossRatio;

    const scoreStats = getScoreStats(
      teamId,
      filteredScorecardData,
      teamTotalsFor
    );
    rowData.averageRunsPerWicket = scoreStats.averageRunsPerWicket;
    rowData.runsPerOver = scoreStats.runsPerOver;
    rowData.numInnings = scoreStats.numInnings;
    rowData.high_score = scoreStats.high_score;
    rowData.low_score = scoreStats.low_score;
    rowData.avg_score = scoreStats.avg_score;

    // Show only the teams that has played at least 1 match
    rowData.matches > 0 && data.push(rowData);
  });

  return data;
};

const getMatchStats = (teamId, scorecards) => {
  let numMatch = 0,
    numWin = 0,
    numLoss = 0,
    numTies = 0,
    numNoResult = 0;

  const seasons = [];

  scorecards.forEach((scorecard) => {
    // Return if the team is not involved in the match
    if (!isTeamPlaying(teamId, scorecard)) return;

    numMatch++;

    // For calculating "Span"
    seasons.push(+scorecard.season);

    switch (
      getMatchResult(
        teamId,
        scorecard.winningTeam,
        scorecard.innings[0].score,
        scorecard.innings[1].score
      )
    ) {
      case "W": {
        numWin++;
        break;
      }
      case "L": {
        numLoss++;
        break;
      }
      case "T": {
        numTies++;
        break;
      }
      case "NR": {
        numNoResult++;
        break;
      }
      default:
        console.error("Unexpected match result");
    }
  });

  let winLossRatio = numLoss !== 0 ? numWin / numLoss : "-";
  winLossRatio = winLossRatio !== "-" ? winLossRatio.toFixed(3) : winLossRatio;

  seasons.sort((a, b) => a - b);
  const span = `${seasons[0]}-${seasons[seasons.length - 1]}`;

  //   console.log({ numMatch, numWin, numLoss, numTies, numNoResult, winLossRatio, span});
  return {
    numMatch,
    numWin,
    numLoss,
    numTies,
    numNoResult,
    winLossRatio,
    span,
  };
};

const getScoreStats = (teamId, scoreCards, teamTotalsFor) => {
  // Logic for parsing thru the scorecards and finding the score statistics like averageRunsPerWIcket,
  // runsPerOver, num of innings, highest, lowest, and average score for a team.

  let averageRunsPerWicket, runsPerOver;

  let totalRuns = 0,
    totalWickets = 0,
    totalBalls = 0,
    numInnings = 0;

  let high_score = Number.NEGATIVE_INFINITY,
    low_score = Number.POSITIVE_INFINITY,
    avg_score;

  scoreCards.forEach((scorecard) => {
    // Return if the team is not involved in the match
    if (!isTeamPlaying(teamId, scorecard)) return;

    const teamIdx = getTeamPlayingIdx(teamId, scorecard.teams);

    scorecard.innings.forEach((inning, i) => {
      if (teamTotalsFor === "batting") {
        // If we are looking "batting" team totals, then return if the team is not batting in this inning
        // or the inning did not have a single ball bowled.
        if (inning.battingTeamInd !== teamIdx || inning.balls === 0) return;
      } else if (teamTotalsFor === "bowling") {
        // If we are looking "bowling" team totals, then return if the team is batting in this inning
        //or the inning did not have a single ball bowled.
        if (inning.battingTeamInd === teamIdx || inning.balls === 0) return;
      }

      numInnings++;
      totalRuns += inning.score;
      totalBalls += inning.balls;
      totalWickets += inning.wickets;

      // Highest team score
      high_score = Math.max(high_score, inning.score);

      // Lowest team score with innings completion
      // 1st innings: all out or overs done
      // 2nd innings: all out or overs done or innings done with a LOST result
      const matchResult = getMatchResult(
        teamId,
        scorecard.winningTeam,
        scorecard.innings[0].score,
        scorecard.innings[1].score
      );

      if (i === 0) {
        // First Innings
        if (
          inning.wickets === scorecard.numPlayers[teamIdx] - 1 ||
          inning.balls === scorecard.numOvers * 6 ||
          matchResult !== "NR"
        ) {
          low_score = Math.min(low_score, inning.score);
        }
      } else if (i === 1) {
        // Second Innings
        if (
          inning.wickets === scorecard.numPlayers[teamIdx] - 1 ||
          inning.balls === scorecard.numOvers * 6 ||
          matchResult === "L"
        ) {
          low_score = Math.min(low_score, inning.score);
        }
      }
    });
  });

  averageRunsPerWicket =
    totalWickets !== 0 ? (totalRuns / totalWickets).toFixed(2) : "-";

  runsPerOver = calculateRPO(totalRuns, totalBalls);

  high_score = high_score === Number.NEGATIVE_INFINITY ? "-" : high_score;
  low_score = low_score === Number.POSITIVE_INFINITY ? "-" : low_score;

  // TODO: Determine if this is even needed or meaningful?
  avg_score = Math.round(totalRuns / numInnings);
  avg_score = Number.isNaN(avg_score) ? "-" : avg_score;

  return {
    averageRunsPerWicket,
    runsPerOver,
    numInnings,
    high_score,
    low_score,
    avg_score,
  };
};

//---------------------------------------------------
// VIEW FORMAT = INNINGS

const innByInnColumns = [
  {
    Header: "Team",
    accessor: "team",
    tooltip: "Sort by team name",
  },
  {
    Header: "Score",
    accessor: "score",
    sortDescFirst: true,
    tooltip: "Sort by team score",
  },
  {
    Header: "Overs",
    accessor: "overs",
    sortDescFirst: true,
    tooltip: "Sort by team overs",
  },
  {
    Header: "RPO",
    accessor: "runsPerOver",
    sortDescFirst: true,
    tooltip: "Sort by average runs per over",
  },
  {
    Header: "Inns",
    accessor: "innings",
    tooltip: "Sort by innings number in each match",
  },
  {
    Header: "Result",
    accessor: "result",
    sortDescFirst: true,
    tooltip: "Sort by result of match",
  },
  {
    Header: "Opposition",
    accessor: "opposition",
    tooltip: "Sort by opposition team name",
  },
  {
    Header: "Tournament",
    accessor: "tournament",
    tooltip: "Sort by tournament name",
  },
  {
    Header: "Ground",
    accessor: "ground",
    tooltip: "Sort by ground played on",
  },
  {
    Header: "Date",
    accessor: "date",
    sortType: (a, b) => {
      // Custom sorting logic goes here
      // Return a value indicating how the rows should be sorted
      const dateA = new Date(a.original.date);
      const dateB = new Date(b.original.date);
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    },
    sortDescFirst: true,
    tooltip: "Sort by start date",
  },

  {
    Header: "Scorecard",
    accessor: "scorecard",
    Cell: ({ row }) => (
      <Link
        to={{
          pathname: row.original.scorecard,
          state: { data: row },
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        Link
      </Link>
    ),
    // filterable: true,
    disableSortBy: true,
    tooltip: "Scorecard link",
  },
];

const getInnByInnData = (
  scorecardData,
  queryData,
  queryContent,
  teamTotalsFor
) => {
  const data = [];

  const teams = getPrimaryTeams(queryData.Teams, queryContent.Teams);

  const oppTeams = getOppositionTeams(
    queryData.OppositionTeams,
    queryContent.Teams
  );

  teams.forEach(({ value: teamId, label: teamName }) => {
    // Filter scorecards by opposition teams
    // -- filter for scorecards where this team (teamId) is playing one of the opponent teams
    let filteredScorecardData = filterScorecardsByOpposition(
      scorecardData,
      oppTeams,
      teamId
    );
    // Filter scorecards based on MatchResult query
    filteredScorecardData = filterScorecardsByMatchResults(
      filteredScorecardData,
      queryData.MatchResult,
      teamId
    );

    // Filter scorecards based on Batting order (Batting first or second) query
    filteredScorecardData = filterScorecardsByBattingOrder(
      filteredScorecardData,
      queryData.BattingOrder,
      teamId
    );

    filteredScorecardData.forEach((scorecard) => {
      // Return if the team is not involved in the match
      if (!isTeamPlaying(teamId, scorecard)) return;

      const rowData = {};

      rowData.team = teamName;

      const inningStats = getInningStats(teamId, scorecard, teamTotalsFor);
      rowData.score = inningStats.score;
      rowData.overs = inningStats.overs;
      rowData.runsPerOver = inningStats.runsPerOver;
      rowData.innings = inningStats.innNum;

      switch (
        getMatchResult(
          teamId,
          scorecard.winningTeam,
          scorecard.innings[0].score,
          scorecard.innings[1].score
        )
      ) {
        case "W": {
          rowData.result = "Win";
          break;
        }
        case "L": {
          rowData.result = "Loss";
          break;
        }
        case "T": {
          rowData.result = "Tie";
          break;
        }
        case "NR": {
          rowData.result = "NR";
          break;
        }
        default:
          console.error("Unexpected match result");
      }

      // Opposition team
      const oppTeamId = findOppositionTeam(teamId, scorecard);
      const oppTeam = queryContent.Teams.find(
        (team) => team.value === oppTeamId
      );
      rowData.opposition = oppTeam.label;

      // Tournament
      rowData.tournament = scorecard.tournamentName;

      // Ground
      const venue = queryContent.Venues.find(
        (venue) => venue.value === scorecard.venue
      );
      rowData.ground = venue && venue.label; // Incase venue is missing in scorecard.

      // Match Date
      const matchDate = new Date(scorecard.matchDate);
      rowData.date = matchDate.toLocaleDateString();

      // Scorecard link
      rowData.scorecard = `https://${queryContent.Site}/matches/scorecard/${scorecard.matchId}`;

      data.push(rowData);
    });
  });
  return data;
};

const getInningStats = (teamId, scorecard, teamTotalsFor) => {
  const teamIdx = getTeamPlayingIdx(teamId, scorecard.teams);
  let score, overs, runsPerOver, innNum;

  scorecard.innings.forEach((inning, i) => {
    // If we are looking "batting" team totals, then return if the team is not batting in this inning.
    // If we are looking "bowling" team totals, then return if the team is batting in this inning.
    if (teamTotalsFor === "batting" && inning.battingTeamInd !== teamIdx)
      return;
    else if (teamTotalsFor === "bowling" && inning.battingTeamInd === teamIdx)
      return;

    score = `${inning.score}/${inning.wickets}`;
    score = score === "0/0" && inning.balls === 0 ? "DNB" : score;

    overs = `${Math.floor(inning.balls / 6)}.${inning.balls % 6}`;

    runsPerOver = calculateRPO(inning.score, inning.balls);

    innNum = i + 1;
  });

  return { score, overs, runsPerOver, innNum };
};

//--------------------------------------------------
// Common methods

const getPrimaryTeams = (queriedTeams, allTeams) =>
  queriedTeams && queriedTeams.length > 0 ? queriedTeams : allTeams;

const getOppositionTeams = (queriedOppositionTeams, allTeams) =>
  queriedOppositionTeams && queriedOppositionTeams.length > 0
    ? queriedOppositionTeams
    : allTeams;

const isTeamPlaying = (teamId, scorecard) =>
  // Returns true if the team is playing in the match, returns false otherwise.
  scorecard.teamA === teamId || scorecard.teamB === teamId;

const findOppositionTeam = (teamId, scorecard) =>
  // Returns the other team that is playing in the match.
  scorecard.teamA === teamId ? scorecard.teamB : scorecard.teamA;

// TODO: The ties and noresult logic may not be correct. Confirm this with Ankit.
const getMatchResult = (team, winningTeam, firstInnScore, secondInnScore) => {
  // Returns the match result as either "W", "L", "T", "NR".
  let result;
  if (winningTeam) {
    result = winningTeam === team ? "W" : "L";
  } else {
    result =
      firstInnScore !== 0 && firstInnScore === secondInnScore ? "T" : "NR";
  }

  return result;
};

const getTeamPlayingIdx = (teamId, playingTeams) => {
  // Returns the index of the team in the match scorecard
  return playingTeams.indexOf(teamId);
};

const filterScorecardsByOpposition = (scorecardData, oppTeams, teamId) => {
  // Filter scorecards based on the opposition teams in the query data.

  return scorecardData.filter((scorecard) => {
    const currentOppTeamId = findOppositionTeam(teamId, scorecard);

    // If the opposition team of the current scorecard is one of the opponents that we should be looking, then return true.
    return oppTeams.find(
      ({ value: oppTeamId }) => oppTeamId === currentOppTeamId
    );
  });
};

const filterScorecardsByMatchResults = (scorecardData, matchResult, teamId) => {
  // Filter scorecards based on MatchResult query data.
  // Note that we are expecting 'matchResult' here in the form of a set with the possible values 'W', 'L', 'T', 'NR'.

  //   console.log("queryData.MatchResult.size", queryData.MatchResult.size);
  if (matchResult.size === 0 || matchResult.size === 4) {
    return scorecardData;
  }

  scorecardData = scorecardData.filter((scorecard) => {
    return matchResult.has(
      getMatchResult(
        teamId,
        scorecard.winningTeam,
        scorecard.innings[0].score,
        scorecard.innings[1].score
      )
    );
  });

  return scorecardData;
};

const filterScorecardsByBattingOrder = (
  scorecardData,
  battingOrder,
  teamId
) => {
  // Filter scorecards based on Batting order query data.
  // Note that we are expecting possible 'battingOrder' values to be 'first', 'second', or  'either'.

  if (battingOrder === "either") {
    return scorecardData;
  }

  const inn = battingOrder === "first" ? 0 : 1;

  scorecardData = scorecardData.filter((scorecard) => {
    const teamIdx = getTeamPlayingIdx(teamId, scorecard.teams);
    return scorecard.innings[inn].battingTeamInd === teamIdx;
  });

  return scorecardData;
};

const calculateRPO = (score, balls) =>
  balls !== 0 ? ((score / balls) * 6).toFixed(2) : "-";
