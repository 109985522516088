import styled from "styled-components";

const Container = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column; /* Stack components vertically on smaller screens */
  }
`;

const Pane = styled.div`
  flex: ${(props) => props.weight};
`;

function SplitScreen({ children, leftWeight = 1, rightWeight = 1 }) {
  const [left, right] = children;

  return (
    <Container>
      <Pane weight={leftWeight}>{left}</Pane>
      <Pane weight={rightWeight}>{right}</Pane>
    </Container>
  );
}

export default SplitScreen;
